module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"912595224","enableOnDevMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"balintkiraly.com","description":"Curriculum Vitae of Balint Kiraly","contact":{"email":"balint.kiraly@protonmail.com","github":"balintkiraly","linkedin":"kiralybalint","location":"Budapest, Hungary"},"display":"minimal-ui","theme_color":"#663399","background_color":"white","icon":"src/assets/favicon.png","lang":"en-US","start_url":"/","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8ba37b40d1c02460b5f7f770a2b28e09"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":true,"portalZIndex":40},
    }]
